import { render, staticRenderFns } from "./SocialLink.vue?vue&type=template&id=6cba9408&scoped=true"
import script from "./SocialLink.vue?vue&type=script&lang=js"
export * from "./SocialLink.vue?vue&type=script&lang=js"
import style0 from "./SocialLink.vue?vue&type=style&index=0&id=6cba9408&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cba9408",
  null
  
)

export default component.exports